import { Component, Inject, OnInit } from '@angular/core';
import { AppBaseComponent } from "@baseComponent";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { AuthService, PopupService } from "@services";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { filter } from "rxjs/operators";
import { InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { Authentication } from "@models";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: [ './signin.component.scss' ]
})
export class SigninComponent extends AppBaseComponent implements OnInit {

  loginForm: FormGroup;
  loading$: Observable<boolean>;
  rememberMe: string;
  oneTouch: boolean;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private router: Router,
              private alert: PopupService,
              private authServiceOLD: AuthService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private broadcastService: MsalBroadcastService,
              private authService: MsalService) {
    super();
    this.cargarventanilla = this.cargarventanilla.bind(this);
    this.rememberMe = localStorage.getItem('rememberMe') &&
    localStorage.getItem('rememberMe').length > 0 ? localStorage.getItem('rememberMe') : '';
    this.loginForm = this.fb.group(
      {
        email: [ this.rememberMe, [ Validators.required/*, this.isValidEmailFn*/ ] ],
        password: [ '', [ Validators.required, Validators.minLength(6), Validators.maxLength(50) ] ],
        remember: [ this.rememberMe !== '' ]
      }
    );
  }

  ngOnInit(): void {
    this.oneTouch = false;
    document.body.className = 'myScroll';
    // this.loading$ = this.store.select((store) => store.authentication.isLoading);
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })

    const metodoExterno = this.cargarventanilla;
    window.addEventListener('message', function(event) {
      // Verifica el origen del mensaje


      if(event.origin == environment.VUDTS_URL_LOCAL || event.origin == environment.VUDTS_URL)
      {
        if (event.data && event.data.flujo) {
          const datos = event.data;

          localStorage.setItem('datosventanilla',JSON.stringify(datos));
          metodoExterno();
          const respuesta = datos.oid;
          // @ts-ignore
          event.source.postMessage(respuesta, event.origin);
        }
      }
      else {
        console.log('origen no admitido');
      }
    });
  }

  setRememberMeValue = (): void => {
    // console.log(this.loginForm.get('remember').value)
  }

  submitLoginForm = () => {
    if (this.loginForm.invalid) return;
    // TODO Tito
    // if (this.loginForm.get('email').value === '' || this.loginForm.get('password').value === '') return;
    this.oneTouch = true;

    if (this.loginForm.get('remember').value) {
      localStorage.setItem('rememberMe', this.loginForm.get('email').value);
    }

    const payload: Authentication = {
      // email: this.loginForm.get('email').value,
      userName: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value
    };

    //  this.authServiceOLD.login(payload).subscribe(res => {
    this.authServiceOLD.internalLogin(payload).subscribe(res => {
      // this.store.dispatch(NomenclatureAction.LoadNomenclatureArray());
      this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
      /*this.store.subscribe(tienda => {
        localStorage.setItem('oneError', '0');
        localStorage.setItem('state', JSON.stringify(tienda.nomenclature));
      })*/
      this.oneTouch = false;
      this.router.navigate([ '' ]);
    }, error => {
      this.oneTouch = false;
      this.alert.errorAlert('No fue posible autenticarse.', 4000);
    })
    // this.store.dispatch(AuthAction.LoginBegin({payload}));
  }

  AzureLogin = () => {
    this.authServiceOLD.LoginB2CAzure();
  }
  getErrorMessage = (field: string): string => {
    let message;
    switch (field) {
      case 'email':
        if (this.loginForm.get(field).hasError('required')) {
          message = `Es requerido`;
        } else if (this.loginForm.get(field).errors &&
          this.loginForm.get(field).errors['invalidEmail'] &&
          this.loginForm.get(field).errors['invalidEmail'].valid === false) {
          message = `No es válido`;
        }
        break;
      case 'password':
        if (this.loginForm.get(field).hasError('required')) {
          message = `Es requerido`;
        } else if (this.loginForm.get(field).hasError('minlength')) {
          message = `Permite al menos 8 caracteres`;
        } else if (this.loginForm.get(field).hasError('maxlength')) {
          message = `Permite hasta 50 caracteres`;
        }
        break;
    }
    return message;
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  cargarventanilla()
  {
    this.authServiceOLD.loginventanilla();
  }

}
