import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthModel, Authentication, SSOAuthResponse } from '@models';
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { ROUTES_PATH } from "@enums";
import {RequestService} from "./request.service";
import {MsalService} from "@azure/msal-angular";
import {PopupService} from "./popup.service";
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  urlInter = 'https://login-appservice-back2.azurewebsites.net/auth/';
  public currentUserSubject: BehaviorSubject<SSOAuthResponse>;
  public currentUser: Observable<SSOAuthResponse>;
  private users!: AuthModel[];
  private userByDefault!: SSOAuthResponse;
  counterChangesRefresh = 1;
  response = false;

  subSolicitante = new BehaviorSubject(null);
  subSolicitante$ = this.subSolicitante.asObservable();

  typeLogin = new BehaviorSubject(null);
  typeLogin$ = this.typeLogin.asObservable();

  private noInterceptorHttpClient: HttpClient;

  constructor(private http: HttpClient,private msalService: MsalService,
              handler: HttpBackend, private router: Router,
              private requestService:RequestService,private alert: PopupService) {
    this.users = [];

    this.currentUserSubject = new BehaviorSubject<SSOAuthResponse>(JSON.parse(String(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();

    this.noInterceptorHttpClient = new HttpClient(handler);
  }

  public get currentUserValue(): SSOAuthResponse {
    return this.currentUserSubject.value;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  isCurrentUserAndNotTokenExpired(): boolean {
    try {
      const currentUser = this.currentUserValue;
      const expiry = JSON.parse(atob(currentUser.access_token.split('.')[1])).exp;

      const localTime = Math.floor((new Date).getTime() / 1000);

      if (localStorage.getItem('dateIn') === undefined || localStorage.getItem('dateIn') === null) {
        localStorage.setItem('dateIn', new Date().toString());
      }
      const before = new Date(localStorage.getItem('dateIn'));
      let dataRefresh = 0;
      if (!isNaN(currentUser.refresh_expires_in)) {
        dataRefresh = currentUser.refresh_expires_in;
      }
      let refresh = (0.9 * dataRefresh) / 60;
      const timeReal = new Date();
      const diff = Math.round(((timeReal.getTime() - before.getTime()) / 60000) / this.counterChangesRefresh);
      const valueExpire = !!localStorage.getItem('currentUser') ? !!JSON.parse(localStorage.getItem('currentUser')).expires_in ? JSON.parse(localStorage.getItem('currentUser')).expires_in : 0 : 0;
      const compareRefresh = 0.9 * (valueExpire / 60);
      if (refresh <= diff) {

        this.cleanAll();
        return false;
      } else if (compareRefresh <= diff) {

        return this.response;
      } else {
        //return currentUser && localTime < expiry ? true : false;

        return true;
      }
    } catch (e) {
      return false;
    }
  }

  // login(payload: Authentication): Observable<any> {

  //   return this.http.post<any>(
  //     `${environment.authentication_endPoint}`, {
  //       request: {
  //         userName: payload.userName,
  //         password: payload.password
  //       }
  //     }
  //   ).pipe(
  //     map(res => {
  //       const {
  //         account,
  //         'simple-service': ss
  //       } = this.getDecodedAccessToken(res.objectResponse.access_token).resource_access;
  //       const user = this.getDecodedAccessToken(res.objectResponse.access_token);

  //       let customRol: Rol;
  //       if (ss.roles.includes(Rol.CitizenOrApplicant))
  //         customRol = Rol.CitizenOrApplicant
  //       else if (ss.roles.includes(Rol.Validator))
  //         customRol = Rol.Validator
  //       else if (ss.roles.includes(Rol.Coordinator))
  //         customRol = Rol.Coordinator
  //       else if (ss.roles.includes(Rol.Specialist))
  //         customRol = Rol.Specialist
  //       else if (ss.roles.includes(Rol.VicePrincipal))
  //         customRol = Rol.VicePrincipal
  //       else if (ss.roles.includes(Rol.ReviewerES))
  //         customRol = Rol.ReviewerES
  //       else if (ss.roles.includes(Rol.ReviewerEN))
  //         customRol = Rol.ReviewerEN
  //       else if (ss.roles.includes(Rol.Supervisor))
  //         customRol = Rol.Supervisor
  //       else if (ss.roles.includes(Rol.SeniorReviewer))
  //         customRol = Rol.SeniorReviewer

  //       const currentUser = {
  //         access_token: res.objectResponse.access_token,
  //         sub: res.objectResponse.sub,
  //         refresh_token: res.objectResponse.refresh_token,
  //         refresh_expires_in: res.objectResponse.refresh_expires_in,
  //         notBeforePolicy: res.objectResponse.notBeforePolicy,
  //         scope: res.objectResponse.scope,
  //         id_token: res.objectResponse.id_token,
  //         token_type: res.objectResponse.token_type,
  //         session_state: res.objectResponse.session_state,
  //         expires_in: res.objectResponse.expires_in,
  //         rol: customRol,
  //         sid: user.sid,
  //         email_verified: user.email_verified,
  //         name: user.name,
  //         preferred_username: user.preferred_username,
  //         given_name: user.given_name,
  //         family_name: user.family_name,
  //         email: user.email
  //       };
  //       localStorage.setItem('currentUser', JSON.stringify(currentUser));
  //       this.currentUserSubject.next(currentUser);
  //       return currentUser;
  //     }));
  // }

  // logOut(refreshToken: SSOAuthResponse['refresh_token']): Observable<boolean> {
  //   this.currentUserSubject.next(null);
  //   return this.http.post<any>(
  //     `${environment.logoutSSO}`, {
  //       request: {
  //         refresh_token: refreshToken
  //       }
  //     }
  //   ).pipe(map(res => {
  //     localStorage.clear();
  //     return true;
  //   }))
  // }

  setCurrentUser(claims: any, idToken?: any, refreshToken?: any): Observable<SSOAuthResponse> {
    const currentUser: SSOAuthResponse = {
      access_token: idToken ? idToken : "token",
      refresh_token: refreshToken ? refreshToken : "refresh_token",
      refresh_expires_in: 10000,
      notBeforePolicy: 100000,
      id_token: "id_token",
      session_state: "session_state",
      expires_in: claims['exp'],
      rol: "solicitante",
      sid: "sid",
      email_verified: true,
      name: claims['name'],
      preferred_username: claims['emails'][0],
      given_name: claims['given_name'],
      family_name: claims['family_name'],
      email: claims['emails'],
      secondName: claims['extension_SegundoNombre'],
      secondSurname: claims['extension_SegundoApellido'],
      phone: claims['extension_TelefonoCelular'],
      extension_TipodeIdentificacion: claims['extension_TipodeIdentificacion'],
      documentNumber: claims['extension_NumerodeIdentificacion'],
      extension_Juridico: claims['extension_Juridico'],
      aud: claims['aud'],
      auth_time: claims['auth_time'],
      // city: claims['city'],
      emails: claims['emails'],
      // jobTitle: claims['jobTitle'],
      sub: claims['sub']
    };

    this.currentUserSubject.next(currentUser);
    return of(currentUser);
  }

  setCurrentUserB2CAzure(claims: any, idToken?: any, refreshToken?: any): any {
    this.requestService.getRoleByIdUser(claims.oid).subscribe(role =>
      {
        if(role.data!=null)
        {
          const currentUser = {
            access_token: idToken ? idToken : "token",
            refresh_token: refreshToken ? refreshToken : "refresh_token",
            refresh_expires_in: 10000,
            notBeforePolicy: 100000,
            id_token: "id_token",
            session_state: "session_state",
            expires_in: claims['exp'],
            rol: role.data[0].value.toLowerCase()=='ciudadano'? 'solicitante' : (role.data[0].value.toLowerCase()=='funcionario'?
            'validador' : role.data[0].value.toLowerCase()),
            sid: "",
            email_verified: role.data[0].email ? role.data[0].email : false,
            name: claims['name'],
            preferred_username: claims['username'],
            given_name: claims['given_name'],
            family_name: claims['family_name'],
            email: role.data[0].email,
            secondName: claims['extension_SegundoNombre'],
            secondSurname: claims['extension_SegundoApellido'],
            phone: claims['extension_TelefonoCelular'],
            extension_TipodeIdentificacion: claims['extension_TipodeIdentificacion'],
            documentNumber: claims['extension_NumerodeIdentificacion'],
            extension_Juridico: claims['extension_Juridico'],
            aud: claims['aud'],
            auth_time: claims['auth_time'],
            emails: claims['emails'],
            sub: claims['sub']
          };
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.currentUserSubject.next(currentUser);
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
        else {
          this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
        }

      });
  }


  internalLogin(payload: any): Observable<any> { //Authentication
    return this.http.post<any>(`${ this.urlInter }login`, {
        email: payload.userName,
        pwd: payload.password
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    ).pipe(map(res => {
      let decodedToken = this.getDecodedAccessToken(res.accessToken)
      let parsedRoles = JSON.parse(decodedToken.access)
      let procedure16Roles = parsedRoles.filter((element) => {
        return element.ProcedureName == "Tramite 16"
      })
      let role = procedure16Roles[0].RolesDto[0].Rol

      const currentUser = {
        access_token: res.accessToken,
        sub: decodedToken.sub,
        refresh_token: res.refreshToken,
        refresh_expires_in: res.refreshTokenExpiresIn,
        notBeforePolicy: res.notBeforePolicy ? res.notBeforePolicy : '',
        scope: res.scope ? res.scope : '',
        id_token: res.id_token ? res.id_token : '',
        token_type: res.tokenType,
        session_state: res.session_state ? res.session_state : '',
        expires_in: res.accessTokenExpiresIn,
        rol: role.toLowerCase(),
        sid: res.sid ? res.sid : '',
        email_verified: res.email_verified ? res.email_verified : false,
        name: `${ decodedToken.name } ${ decodedToken.surname }`,
        preferred_username: decodedToken.username,
        given_name: decodedToken.name,
        family_name: decodedToken.surname,
        email: decodedToken.email,
        userId: res.userId,
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
      this.typeLogin.next("SoaintB2C");

      return currentUser;
    }));
  }

  public LoginB2CAzure(): any {
    this.msalService.loginPopup().subscribe(res => {

        // @ts-ignore


        let json = {
          oid : res.account.idTokenClaims.oid,
          email : res.account.username
        }

        this.addUser(json).subscribe(resAddUser => {

          if(resAddUser.data){
            this.getCodeVentanillaByMail(res.account.username).subscribe(resGetCodeVentanilla => {

              let json = {
                oid : res.account.idTokenClaims.oid,
                email : res.account.username,
                nombreCompleto : res.account.name,
                created : moment(new Date()).format("YYYY-MM-DD")+"",
                idPersonaVentanilla : resGetCodeVentanilla,
                isEnabled : true
              }
              this.updateUser(json).subscribe(resUpdateUser => {

                this.updateFieldUserRole(`${res.account.username};${resGetCodeVentanilla}`, res.account.idTokenClaims.oid).subscribe(resUpdateFieldUserRole => {

                  let json = {
                    idUser : res.account.idTokenClaims.oid,
                    idRole : "58EDA51F-7E19-47C4-947F-F359BD1FC732"
                  }
                  this.addUserRole(json).subscribe(resAddUserRole => {

                    this.requestService.getRoleByIdUser(res.account.idTokenClaims.oid).subscribe(role => {
                      if(role.data!=null)
                      {
                        const currentUser = {
                          access_token: res.idToken,
                          sub: res.account.idTokenClaims.sub,
                          refresh_token: res.account.idTokenClaims.auth_time+"",
                          refresh_expires_in: res.account.idTokenClaims.exp,
                          notBeforePolicy: res.account.idTokenClaims.iat ? res.account.idTokenClaims.iat : 0,
                          scope: res.scopes[0] ? res.scopes[0] : '',
                          id_token: res.idToken ? res.idToken : '',
                          token_type: res.tokenType,
                          session_state: res.state ? res.state : '',
                          expires_in: res.account.idTokenClaims.exp,
                          rol: role.data[0].value.toLowerCase()=='ciudadano'? 'solicitante' : (role.data[0].value.toLowerCase()=='funcionario'?
                            'validador' : role.data[0].value.toLowerCase()) ,
                          sid: '',
                          email_verified: role.data[0].email ? role.data[0].email : false,
                          name: res.account.idTokenClaims.name,
                          preferred_username: res.account.username,
                          given_name:res.account.idTokenClaims.given_name+"",
                          family_name: res.account.idTokenClaims.family_name+"",
                          email: role.data[0].email,
                          userId: res.account.idTokenClaims.oid,
                        };
                        localStorage.setItem('currentUser', JSON.stringify(currentUser));
                        this.currentUserSubject.next(currentUser);
                        this.typeLogin.next("AzureB2C");

                        this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
                        this.router.navigate([ '' ]);
                      }
                      else {
                        this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
                      }
                    });
                  });
                });
              });
            });

          }else{
            this.requestService.getRoleByIdUser(res.account.idTokenClaims.oid).subscribe(role => {
              if(role.data!=null)
              {
                const currentUser = {
                  access_token: res.idToken,
                  sub: res.account.idTokenClaims.sub,
                  refresh_token: res.account.idTokenClaims.auth_time+"",
                  refresh_expires_in: res.account.idTokenClaims.exp,
                  notBeforePolicy: res.account.idTokenClaims.iat ? res.account.idTokenClaims.iat : 0,
                  scope: res.scopes[0] ? res.scopes[0] : '',
                  id_token: res.idToken ? res.idToken : '',
                  token_type: res.tokenType,
                  session_state: res.state ? res.state : '',
                  expires_in: res.account.idTokenClaims.exp,
                  rol: role.data[0].value.toLowerCase()=='ciudadano'? 'solicitante' : (role.data[0].value.toLowerCase()=='funcionario'?
                    'validador' : role.data[0].value.toLowerCase()) ,
                  sid: '',
                  email_verified: role.data[0].email ? role.data[0].email : false,
                  name: res.account.idTokenClaims.name,
                  preferred_username: res.account.username,
                  given_name:res.account.idTokenClaims.given_name+"",
                  family_name: res.account.idTokenClaims.family_name+"",
                  email: role.data[0].email,
                  userId: res.account.idTokenClaims.oid,
                };
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
                this.currentUserSubject.next(currentUser);
                this.typeLogin.next("AzureB2C");

                this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
                this.router.navigate([ '' ]);
              }
              else {
                this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
              }
            });
          }
        });
      }
    )
  }

  public loginventanilla()
  {
    const ventanilla=JSON.parse( localStorage.getItem('datosventanilla'));

    let json = {
      oid : ventanilla.oid,
      email : ventanilla.flujo.account.username
    }

    this.addUser(json).subscribe(resAddUser => {

      if(resAddUser.data){
        this.getCodeVentanillaByMail(ventanilla.flujo.account.username).subscribe(resGetCodeVentanilla => {

          let json = {
            oid : ventanilla.flujo.account.idTokenClaims.oid,
            email : ventanilla.flujo.account.username,
            nombreCompleto : ventanilla.flujo.account.name,
            created : moment(new Date()).format("YYYY-MM-DD")+"",
            idPersonaVentanilla : ventanilla.flujo,
            isEnabled : true
          }
          this.updateUser(json).subscribe(resUpdateUser => {

            this.updateFieldUserRole(`${ventanilla.flujo.account.username};${resGetCodeVentanilla}`, ventanilla.flujo.account.idTokenClaims.oid).subscribe(resUpdateFieldUserRole => {

              let json = {
                idUser : ventanilla.oid,
                idRole : "58EDA51F-7E19-47C4-947F-F359BD1FC732"
              }
              this.addUserRole(json).subscribe(resAddUserRole => {

                this.requestService.getRoleByIdUser(ventanilla.oid).subscribe(role => {
                  if(role.data!=null)
                  {
                    const currentUser = {
                      access_token: ventanilla.flujo.idToken,
                      sub: ventanilla.flujo.account.idTokenClaims.sub,
                      refresh_token: ventanilla.flujo.account.idTokenClaims.auth_time+"",
                      refresh_expires_in: ventanilla.flujo.account.idTokenClaims.exp,
                      notBeforePolicy: ventanilla.flujo.account.idTokenClaims.iat ? ventanilla.flujo.account.idTokenClaims.iat : 0,
                      scope: ventanilla.flujo.scopes[0] ? ventanilla.flujo.scopes[0] : '',
                      id_token: ventanilla.flujo.idToken ? ventanilla.flujo.idToken : '',
                      token_type: ventanilla.flujo.tokenType,
                      session_state: ventanilla.flujo.state ? ventanilla.flujo.state : '',
                      expires_in: ventanilla.flujo.account.idTokenClaims.exp,
                      rol: role.data[0].value.toLowerCase()=='ciudadano'? 'solicitante' : (role.data[0].value.toLowerCase()=='funcionario'?
                        'validador' : role.data[0].value.toLowerCase()) ,
                      sid: '',
                      email_verified: role.data[0].email ? role.data[0].email : false,
                      name: ventanilla.flujo.account.idTokenClaims.name,
                      preferred_username: ventanilla.flujo.account.username,
                      given_name:ventanilla.flujo.account.idTokenClaims.given_name+"",
                      family_name: ventanilla.flujo.account.idTokenClaims.family_name+"",
                      email: role.data[0].email,
                      userId: ventanilla.flujo.account.idTokenClaims.oid,
                    };
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    this.currentUserSubject.next(currentUser);
                    this.typeLogin.next("AzureB2C");

                    this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
                    this.router.navigate([ '' ]);
                  }
                  else {
                    this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
                  }
                });
              });
            });
          });
        });

      }else{
        this.requestService.getRoleByIdUser(ventanilla.flujo.account.idTokenClaims.oid).subscribe(role => {
          if(role.data!=null)
          {
            const currentUser = {
              access_token: ventanilla.flujo.idToken,
              sub: ventanilla.flujo.account.idTokenClaims.sub,
              refresh_token: ventanilla.flujo.account.idTokenClaims.auth_time+"",
              refresh_expires_in: ventanilla.flujo.account.idTokenClaims.exp,
              notBeforePolicy: ventanilla.flujo.account.idTokenClaims.iat ? ventanilla.flujo.account.idTokenClaims.iat : 0,
              scope: ventanilla.flujo.scopes[0] ? ventanilla.flujo.scopes[0] : '',
              id_token: ventanilla.flujo.idToken ? ventanilla.flujo.idToken : '',
              token_type: ventanilla.flujo.tokenType,
              session_state: ventanilla.flujo.state ? ventanilla.flujo.state : '',
              expires_in: ventanilla.flujo.account.idTokenClaims.exp,
              rol: role.data[0].value.toLowerCase()=='ciudadano'? 'solicitante' : (role.data[0].value.toLowerCase()=='funcionario'?
                'validador' : role.data[0].value.toLowerCase()) ,
              sid: '',
              email_verified: role.data[0].email ? role.data[0].email : false,
              name: ventanilla.flujo.account.idTokenClaims.name,
              preferred_username: ventanilla.flujo.account.username,
              given_name:ventanilla.flujo.account.idTokenClaims.given_name+"",
              family_name: ventanilla.flujo.account.idTokenClaims.family_name+"",
              email: role.data[0].email,
              userId: ventanilla.flujo.account.idTokenClaims.oid,
            };
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.currentUserSubject.next(currentUser);
            this.typeLogin.next("AzureB2C");

            this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
            this.router.navigate([ '' ]);
          }
          else {
            this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
          }
        });
      }
    });
  }




  internalRefresh(payload: any): Observable<any> { //Authentication
    return this.http.post<any>(`${ this.urlInter }refresh-token`, {
        tokenValue: payload.tokenValue ? payload.tokenValue : "f9bb62c81fe446d6a990754b5721a417",
        userID: payload.userID ? payload.userID : "b14766fd-cda2-4254-aa9b-b072dd3bc2d5"
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    ).pipe(map(res => {
      const currentUser = {
        access_token: res.accessToken,
        sub: payload.user.sub ? payload.user.sub : '',
        refresh_token: res.refreshToken,
        refresh_expires_in: res.refreshTokenExpiresIn,
        notBeforePolicy: payload.user.notBeforePolicy ? payload.user.notBeforePolicy : '',
        scope: payload.user.scope ? payload.user.scope : '',
        id_token: payload.user.id_token ? payload.user.id_token : '',
        token_type: res.tokenType,
        session_state: payload.user.session_state ? payload.user.session_state : '',
        expires_in: res.accessTokenExpiresIn,
        rol: payload.user.rol ? payload.rol : '',
        sid: payload.user.sid ? payload.user.sid : '',
        email_verified: payload.user.email_verified ? payload.user.email_verified : false,
        name: payload.user.name ? payload.user.name : '',
        preferred_username: payload.user.preferred_username ? payload.user.preferred_username : '',
        given_name: payload.user.given_name ? payload.user.given_name : '',
        family_name: payload.user.family_name ? payload.user.family_name : '',
        email: payload.user.email,
        userId: res.userId,
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
      return currentUser;
    }));
  }

  externalRefresh(refresh_token: any, usuario: any): Observable<any> { //Authentication
    let body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    body.set('client_id', "2f9ffa76-cca3-40ab-b95f-ac882b8e5b9a");
    body.set('redirect_uri', "/");

    return this.http.post<any>(`https://soaintje.b2clogin.com/soaintje.onmicrosoft.com/B2C_1_iniciosesion/oauth2/v2.0/token?` + body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }
    ).pipe(map(res => {
      const currentUser = {
        access_token: res.id_token ? res.id_token : '',
        expires_in: res.id_token_expires_in ? res.id_token_expires_in : '',
        documentNumber: usuario.documentNumber,
        sub: usuario.sub ? usuario.sub : '',
        refresh_token: res.refresh_token,
        refresh_expires_in: res.refresh_token_expires_in,
        notBeforePolicy: res.not_before ? res.not_before : '',
        scope: res.scope ? res.scope : '',
        id_token: "id_token",
        rol: usuario.rol ? usuario.rol : 'solicitante',
        sid: usuario.sid ? usuario.sid : '',
        email_verified: usuario.email_verified ? usuario.email_verified : false,
        name: usuario.name ? usuario.name : '',
        preferred_username: usuario.preferred_username ? usuario.preferred_username : '',
        given_name: usuario.given_name ? usuario.given_name : '',
        family_name: usuario.family_name ? usuario.family_name : '',
        email: usuario.preferred_username,
        session_state: "id_token"
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
      return currentUser;
    }));
  }

  internalLogout(payload: any): Observable<any> { //Authentication
    return this.http.post<any>(`${ this.urlInter }logout`, {
        tokenValue: payload.tokenValue ? payload.tokenValue : "abc092a9eb20409bb77ff3fa40774a9b",
        userID: payload.userID ? payload.userID : "b14766fd-cda2-4254-aa9b-b072dd3bc2d5"
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    );
  }

  internalUsersByRole(rol: string): Observable<any> {
    return this.http.post<any>(`${ this.urlInter }users-by-role/${ rol }`,
      {
        "ClientId": "e6fae8b8-c8a2-47ac-8244-df3062897195"
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Cookie': '.cookhouse=login-appservice-back2.azurewebsites.net'
        })
      }
    );
  }

  externalB2CAuth(): Observable<any> {
    return this.http.post<any>(`https://login-appservice-back2.azurewebsites.net/auth/users-token`,
      { "clientId": "19c516fc-e0ac-4422-a041-c1f119d0b11a" },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    );
  }

  externalGetUserByRole(token, user): Observable<any> {
    const params = '$select=id,displayName,givenName,surname,emails,identities'
    const extensionParams = 'extension_78429ced64144f2ca1747107feb82edd_TelefonoCelular,extension_78429ced64144f2ca1747107feb82edd_NumerodeIdentificacion,extension_78429ced64144f2ca1747107feb82edd_SegundoNombre,extension_78429ced64144f2ca1747107feb82edd_SegundoApellido, extension_78429ced64144f2ca1747107feb82edd_TipodeIdentificacion,extension_78429ced64144f2ca1747107feb82edd_Juridico'
    return this.noInterceptorHttpClient.get<any>(`https://graph.microsoft.com/v1.0/users/${ user }?${ params },${ extensionParams }`,
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${ token }`
        })
      }
    );
  }

  cleanAll() {

    /*localStorage.removeItem('currentUser');
    localStorage.removeItem('state');
    localStorage.removeItem('tamrequest');*/
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigateByUrl(ROUTES_PATH.SignIn);
  }

  addUser(json: any): Observable<any> {
    return this.http.post(`${ environment.PROCEDURE_SECURITY_URI }/AddUser`, json);
  }

  getCodeVentanillaByMail(email: string): Observable<any> {
    return this.http.get(`${ environment.apiUrlFuncionario }/api/v1/UserRole/GetVentanillaCodeByEmail/${email}`, {responseType:'text'});
  }

  updateUser(json: any): Observable<any> {
    return this.http.put(`${ environment.PROCEDURE_SECURITY_URI }/UpdateUser`, json);
  }

  addUserRole(json: any): Observable<any> {
    return this.http.post(`${ environment.PROCEDURE_SECURITY_URI }/AddUserRole`, json);
  }

  updateFieldUserRole(emailCode : string, oid : string): Observable<any> {
    return this.http.patch(`${ environment.apiUrlFuncionario }/api/v1/UserRole/UpdateField/${emailCode}/${oid}`, {responseType:'text'});
  }
}
