import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  setAddress = new BehaviorSubject(null);
  getAddress$ = this.setAddress.asObservable();

  consultAddress = new BehaviorSubject(false);
  consultAddress$ = this.consultAddress.asObservable();

  setServiceAddress = new BehaviorSubject(null);
  getServiceAddress$ = this.setServiceAddress.asObservable();

  constructor(private http: HttpClient) {
  }

  /*getAddress(address: string): Observable<any> {
    let json = {
      "address": address,
      "user": "",
      "key": ""
    }
    return this.http.post<any>(`${ environment.addressUrl }/api/address`, json);
  }*/

  /*getAddress2(adress : string): any{
    let bodyRequest = `<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Body><obtenerCodDireccion xmlns="http://200.75.49.126/WsDireccion"><Direccion>${adress}</Direccion><usuario></usuario><clave></clave></obtenerCodDireccion></soap:Body></soap:Envelope>`;
    let xmlResponse;
    fetch("http://sig.saludcapital.gov.co/wsdireccion/direccion.asmx", {
      method: "POST",
      body: bodyRequest,
      headers: { "Content-type": "text/xml; charset=utf-8", "SOAPAction": "http://200.75.49.126/WsDireccion/obtenerCodDireccion" }
    }).then(response => response.text()).then(xml => {
      xmlResponse = xml;
      console.log(xml);
    }).catch (err => console.log(err));

    xmlResponse = `<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body><obtenerCodDireccionResponse xmlns="http://200.75.49.126/WsDireccion"><obtenerCodDireccionResult>05032-0-012-0-068;16;108;16020BD;-74.09345312;4.61562595;0;2;51;;31 Encontrado en Malla Vial Exacta</obtenerCodDireccionResult></obtenerCodDireccionResponse></soap:Body></soap:Envelope>`;

    return xmlResponse;
  }*/

  /*getZone(): Observable<any> {
    return this.http.get<any>(`${ environment.addressUrl }/api/address/zone`);
  }

  getLocality(): Observable<any> {
    return this.http.get<any>(`${ environment.addressUrl }/api/address/locality`);
  }

  getUpz(id: number): Observable<any> {
    return this.http.get<any>(`${ environment.addressUrl }/api/address/upz/${ id }`);
  }

  getNeighborhood(id: number): Observable<any> {
    return this.http.get<any>(`${ environment.addressUrl }/api/address/neighborhood/${ id }`);
  }*/
}
