export enum StatusRequest {
  REQUEST_CREATED = 1,
  REQUEST_SENDED = 2,
  REQUEST_CANCELED = 3,
}

export const DocTypesShared = {
  1: 'Cédula de ciudadanía',
  2: 'Cédula de extranjería',
  3: 'Tarjeta de identidad',
  4: 'Permiso especial de permanencia',
  5: 'NIT',
  6: 'Pasaporte',
  7: 'Número de Protocolo Medicina Legal',
  8: 'Tarjeta de Extranjería',
  9: 'Registro Civil',
  10: 'Número Único de Identificación Personal',
  11: 'Sin Identificación',
  12: 'Otro Documento',
  13: 'Permiso Protección Temporal',
  null: 'Sin Dato',
  0: 'Sin Dato',
  'NaN': 'Sin Dato'
};
