import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Constant } from '../models/constant.model';
import { NameValue } from '../models/nameValue.model';
import { Requirement } from '../models/requirement.model';

@Injectable({
  providedIn: 'root'
})
export class TramiteSharedService {

  constructor(private http: HttpClient) {
  }



  //API SECURITY

  getUsersByRole(idRole:string):Observable<NameValue[]> {
    return this.http.get<any>(`${environment.PROCEDURE_SECURITY_URI}/GetUserbyIdrol/${idRole}/${environment.numProcedure}`).pipe(map(resp => {
      return resp.data.map(user => {
        return {
          name : user.nombreCompleto,
          value : user.codigoUsuario
        }
      });
    }));
  }


  //API SHARED

  getZones() {
    return this.http.get<any>(`${ environment.PROCEDURE_SHARED_URI }/v1/SubRed/GetSubRed`);
  }

  getLocalities() {
    return this.http.get<any>(`${ environment.PROCEDURE_SHARED_URI }/v1/Localidad/GetAllLocalidad`);
  }

  getUpz() {
    return this.http.get<any>(`${ environment.PROCEDURE_SHARED_URI }/v1/Upz/GetUpz`);
  }

  getBarrios() {
    return this.http.get<any>(`${ environment.PROCEDURE_SHARED_URI }/v1/Barrio/GetBarrios`);
  }

  getBarriosByUpz(idUpz) {
    return this.http.get<any>(`${ environment.PROCEDURE_SHARED_URI }/v1/Barrio/GetBarrioByIdUpz/${ idUpz }`);
  }

  getAddressGeoreferenciador(address : string) {
    return this.http.get<any>(`${ environment.PROCEDURE_SHARED_URI }/v1/Direccion/GetDireccion/${ address }`);
  }


  //API CIUDADANO

  getTypeJobs():Observable<NameValue[]> {
    return this.http.get<NameValue[]>(`${environment.apiUrl}/api/Constant/getDignataryPosition`);
  }

  getConstants(id:string):Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/api/Constant/getConstantByIdFather/${id}`);
  }

  getRequirementsByDignataryJob(id : string):Observable<Requirement[]> {
    return this.http.get<Requirement[]>(`${environment.apiUrl}/api/Constant/getDocumentNameByIdDignataryPosition/${id}`);
  }

  getRequirementsCampus():Observable<Requirement[]>{
    return this.http.get<any>(`${environment.apiUrl}/api/Campus/getReqCampus`).pipe(map(resp => {
      return resp.map(req => {
        return {
          id : req.idReqdocument,
          description : req.namereqDocument,
          required : req.statereqDocument == 1 ? true : false
        }
      });
    }));
  }

  //API FUNCIONARIO
  /*getUsersByRole(idRole:string):Observable<NameValue[]> {
    return this.http.get<any>(`${environment.apiUrlFuncionario}/validationApi/Request/GetUserRole/${idRole}/${environment.numProcedure}`).pipe(map(resp => {
      return resp.map(user => {
        return {
          name : user.idUser,
          value : user.idUser
        }
      });
    }));
  }*/



  getStatusByRoleAndActivityBefore(idRole:string, idActivityBefore:string):Observable<NameValue[]> {
    return this.http.get<any>(`${environment.apiUrlFuncionario}/validationApi/Request/GetFlowActivity/${idRole}/${idActivityBefore}`).pipe(map(resp => {
      return resp.map(status => {
        return {
          name : status.description,
          value : status.idActivityAfter
        }
      });
    }));
  }

  sendEmail(json: any): Observable<any> {
    return this.http.post(`${ environment.apiUrlFuncionario }/validationApi/Email/sendEmail`, json, {responseType:'text'});
  }

  getAuditory(idRequest:string):Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrlFuncionario }/validationApi/Request/GetRequestFlowActivity/${idRequest}`);
  }


  /**
   * Obtiene el historico de notificaciones
   * @param idRequest
   */
  getNotificationsHistory(idRequest: string): Observable<any[]> {
    return this.http.get<any[]>(`${ environment.apiUrlFuncionario }/validationApi/Email/NotificationHistory/${ idRequest }`);
  }
  getPreviewDoc(json: any): Observable<any> {
    return this.http.post(`${ environment.apiUrlFuncionario }/validationApi/CertificateExist/getCertificateExistence`, json, {responseType:'text'});
  }


}
