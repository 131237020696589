import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { Representative } from '@models';

@Injectable({
  providedIn: 'root'
})
export class RepresentativeService {
  representativeSource = new BehaviorSubject(null);
  representativeTarget$ = this.representativeSource.asObservable();

  constructor(private http: HttpClient) {
  }

  addRepresentative(json: any): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }/api/representative`, json,
    );
  }

  getRepresentative(id: number): Observable<any> {
    return this.http.get<Representative>(`${ environment.apiUrl }/api/representative/${ id }`,
    );
  }

  getAllRepresentative(): Observable<any> {
    return this.http.get<Representative[]>(`${ environment.apiUrl }/api/representative`,
    );
  }

  updateRepresentative(json: any, id: number): Observable<any> {
    return this.http.put<any>(`${ environment.apiUrl }/api/representative/${ id }`, json,
    );
  }

  deleteRepresentative(id: number): Observable<any> {
    return this.http.delete<any>(`${ environment.apiUrl }/api/representative/${ id }`,
    );
  }
}
