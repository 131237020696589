export enum ROUTES_PATH {
  SignIn = 'sign-in',
  Dashboard = 'inbox',
  AddRequestProcedure6 = 'procedure6/request/add',
  SearchRequest = 'procedure6/request/add/searchRequest',
  ValidationRequestProcedure = 'procedure6/request/validation',
  CoordinatorRequestProcedure = 'procedure6/request/coordinator',
  SubdirectorRequestProcedure = 'procedure6/request/subdirector',


  //ValidationRequestProcedure16 = 'procedure16/request/validation/:request_id',
  //SpecialistRequestProcedure16 = 'procedure16/request/specialist/:request_id',
  //ViewRequest = 'procedure16/request/edit/:request_id',
  //Revisar Advance Line
  //RevViewRequest = '/procedure16/request/edit/',
  //RevValidationRequestProcedure16 = '/procedure16/request/validation/',
  //ReplenishmentResource = 'replenishment-resource/:numero/:tramite',
}
