import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { Sede } from '@models';
import { SaveCampusInDTO } from '../models/saveCampusDTO.model';

@Injectable({
  providedIn: 'root'
})
export class CampusService {

  campusSource = new BehaviorSubject(null);
  campusTarget$ = this.campusSource.asObservable();

  campusOne = new BehaviorSubject(null);
  campusOne$ = this.campusOne.asObservable();

  totalCampus = new BehaviorSubject(null);
  totalCampus$ = this.totalCampus.asObservable();

  listDeleteIdCampus = new BehaviorSubject(null);
  listDeleteIdCampus$ = this.listDeleteIdCampus.asObservable();

  constructor(private http: HttpClient) {
  }


  saveCampus(json: SaveCampusInDTO[]): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/api/Campus/insertCampus`, json, {responseType:'text'});
  }
  
  getAllCampus(json: any): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }/api/Campus/getAllCampus`, json);
  }

  updateStateCampus(idEsal: string, idRequest: string): Observable<any> {
    return this.http.put(`${ environment.apiUrl }/api/Campus/updateStateCampus/${ idEsal }/${idRequest}`, {responseType:'text'});
  }

  deleteCampus(json : any): Observable<any> {
    return this.http.put(`${ environment.apiUrl }/api/Campus/deleteCampus`, json, {responseType:'text'});
  }

  getCampus(id: number): Observable<any> {
    return this.http.get<Sede>(`${ environment.apiUrl }/api/Campus/GetCampusData/${ id }`,
    );
  }
}
