export enum Rol {
  CitizenOrApplicant = 'solicitante',
  Validator = 'validador',
  Coordinator = 'coordinador',
  Specialist = 'especialista',
  Subdirector = 'subdirector',
  ReviewerES = 'revisor',
  ReviewerEN = 'reviewer',
  Supervisor = 'supervisor',
  SeniorReviewer = 'revisorsuperior'
}
