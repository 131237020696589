import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Log } from '@models';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LogService {
  mock: Log[] = [
];
  constructor(private http: HttpClient) { }

}
