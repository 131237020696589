import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { AppHeaders } from "@models";
import { Router } from "@angular/router";
import { AuthService, PopupService } from "@services";
import { ROUTES_PATH } from "@enums";
import { LoaderService } from '../services/loader.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private activeRequest = 0;

  constructor(private authService: AuthService,
              private router: Router,
              private popupService: PopupService,
              private serviceLoader : LoaderService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.activeRequest === 0){
      this.serviceLoader.showLoader();
    }
    this.activeRequest++;

    let requests = request;
    //const userCountry = 'CU';
    let headers: AppHeaders = {
      //'name-app': 'sds-tramite-16',
      //'mobile-country': userCountry ? userCountry : 'no-mobile-country',
    };

    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser?.access_token) {
      headers = { ...headers, Authorization: `Bearer ${ currentUser?.access_token }` };
    }

    const responseType = request.responseType;
    const isFormData = request.body instanceof FormData;

    if (currentUser && currentUser?.access_token) {
      if (isFormData || responseType === 'blob') {
        requests = request.clone({
          setHeaders: { ...headers },
        })
      } else {
        requests = request.clone({
          setHeaders: {
            ...headers,
            'Content-Type': request.method === 'PATCH' ? /*'application/json-patch+json'*/ 'application/merge-patch+json' : 'application/json'
          }
        });
      }
    }
    return next.handle(requests).pipe(

      finalize(() =>  this.stopLoader()),

      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && localStorage.getItem('oneError') !== '1') {
          this.popupService.errorAlert('Debe autenticarse, por favor.', 5000);
          localStorage.clear();
          this.router.navigateByUrl(ROUTES_PATH.SignIn);

          localStorage.setItem('oneError', '1');
        }
        return throwError(err);
      })
    );
  }

  private stopLoader(){
    this.activeRequest--;
    if(this.activeRequest === 0){
      this.serviceLoader.hideLoader();
    }
  }
}
