import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { Nomenclator } from "@models";

@Injectable({
  providedIn: 'root'
})
export class NomenclatorService {

  setNomenclature = new BehaviorSubject(null);
  getNomenclature$ = this.setNomenclature.asObservable();

  constructor(private http: HttpClient) {
  }

}
