import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES_PATH } from "@enums";
import { SigninComponent } from "./core/components/signin/signin.component";
import { MsalLocalGuard } from "./core/guards/msal-local.guard";

const routes: Routes = [
  {
    path: '',
    canActivate: [ MsalLocalGuard ],
    loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule)
  }, {
    path: ROUTES_PATH.SignIn, component: SigninComponent
  }, {
    path: '**', redirectTo: ROUTES_PATH.Dashboard
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
