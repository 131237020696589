import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { Entity } from '@models';
import { GetEsalXIdUserExternalOutDTO } from '../models/getEsalXIdUserExternalDTO.model';
import { map } from "rxjs/operators";
import { DocTypesShared } from "@enums";

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  entitySource = new BehaviorSubject(null);
  entityTarget$ = this.entitySource.asObservable();

  constructor(private http: HttpClient) {
  }

  addEntity(json: Entity): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }/api/entity`, json,
    );
  }

  getEntity(id: number): Observable<any> {
    return this.http.get<Entity>(`${ environment.apiUrl }/api/entity/${ id }`,
    );
  }

  getEntityByIdUserExternal(idUserExternal: string): Observable<GetEsalXIdUserExternalOutDTO> {
    return this.http.post<GetEsalXIdUserExternalOutDTO>(`${ environment.apiUrl }/api/Esal/getEsalByIdUserExternal/${idUserExternal}`, '')
      .pipe(map(response => {
        if (response != null) {
          response.namedocRlEsal = DocTypesShared[Number(response.typedocRlEsal)] || 'No Disponible';
        }
        return response;
      }))
  }

  updateOrganEsalIn(json: any): Observable<any> {
    return this.http.put(`${ environment.apiUrlFuncionario }/validationApi/Esal/updateOrganEsal`, json, {responseType:'text'});
  }

  getAllEntity(): Observable<any> {
    return this.http.get<Entity[]>(`${ environment.apiUrl }/api/entity`,
    );
  }

  updateEntity(json: Entity, id: number): Observable<any> {
    return this.http.put<any>(`${ environment.apiUrl }/api/entity/${ id }`, json,
    );
  }

  deleteEntity(id: number): Observable<any> {
    return this.http.delete<any>(`${ environment.apiUrl }/api/entity/${ id }`,
    );
  }

  checkEntity(json: { nombreEntidad: string; }): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }/api/entity/checkEntity`, json,
    );
  }
}
